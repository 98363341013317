import { getMeta } from "../../lib/utils/utils";
import Head from "next/head";

export interface MetaListProp {
    meta?: MetaProp[],
    canonical: string,
}
export interface MetaProp {
    tag?: string,
    attributes?: {
        name: string,
        content: string
    }
}


export function Meta({ meta, canonical }: MetaListProp) {
    const title = getMeta(meta, "title");
    const description = getMeta(meta, "description");
    const keywords = getMeta(meta, "keywords");
    const robots = getMeta(meta, "robots");

    return (
        <Head>
            <title>
                {`${title}`}
            </title>

            <link
                rel="canonical"
                href={process.env.NEXT_PUBLIC_BASE_URL + canonical}
            />
            <meta
                key="description"
                name="description"
                content={description}
            />
            <meta
                key="keywords"
                name="keywords"
                content={keywords ? keywords : ''}
            />
            <meta
                key="robots"
                name="robots"
                content={robots ? robots : ''}
            />
            {/* <meta
            key="googlebot"
            name="googlebot"
            content={
              metaTags
                ? `${metaTags.index ? 'index' : 'noindex'},${
                    metaTags.follow ? 'follow' : 'nofollow'
                  }`
                : ''
            }
          /> */}
            <meta
                name="google-site-verification"
                content={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRET}
            />
            <link
                rel="apple-touch-icon"
                sizes="57x57"
                href="/favicon/apple-icon-57x57.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="60x60"
                href="/favicon/apple-icon-60x60.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="72x72"
                href="/favicon/apple-icon-72x72.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="76x76"
                href="/favicon/apple-icon-76x76.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/favicon/apple-icon-114x114.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/favicon/apple-icon-120x120.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/favicon/apple-icon-144x144.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/favicon/apple-icon-152x152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/favicon/apple-icon-180x180.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/favicon/android-icon-192x192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon/favicon-16x16.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/favicon/favicon-96x96.png"
            />
            <link rel="manifest" href="/favicon/manifest.json" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="/favicon/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
}