import { IconType, defaultProps } from './types';

/**
 * Icono CaretLeft
 */
export const CaretLeft = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M14 8v8l-5-4 5-4Z" fill={color} />
  </svg>
);
