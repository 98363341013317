import { IconType, defaultProps } from './types';

/**
 * Icono CaretRight
 */
export const CaretRight = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M10 16V8l5 4-5 4Z" fill={color} />
  </svg>
);
