import { ComponentPropsWithRef, ElementType, Ref, forwardRef } from 'react';
import { oneLine } from 'common-tags';

import { BackgroundImage } from '@/atoms';

import { styleInjector } from '@/lib/utils';
import '@/lib/utils/forwardRef';

import { TypeContainerProps } from './types';

/**
 * El componente Container es totalmente flexible y permite casi cualquier cosa
 */
const BaseContainer = <T extends ElementType = 'div'>(
  {
    as,
    className = '',
    classNameWrapper = '',
    wrapperSize = '',
    wrapper = false,
    backgroundTop,
    backgroundBottom,
    backgroundImage,
    backgroundVideo,
    backgroundLazy = true,
    overlay,
    backgroundColor = 'bg-transparent',
    padding = 'px-4 py-4 md:py-8',
    zIndex = 1,
    children,
    justify,
    textAlign,
    align,
    style,
    ...props
  }: TypeContainerProps<T> &
    Omit<ComponentPropsWithRef<T>, keyof TypeContainerProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
): JSX.Element => {
  const HTMLTag = as || 'div';
  return (
    <HTMLTag
      className={oneLine`
        flex flex-col relative
        ${justify ?? ''}
        ${align ?? ''}
        ${textAlign ?? ''}
        ${padding ?? ''}
        ${className}
        ${backgroundTop || backgroundBottom ? '' : backgroundColor}
      `}
      style={style}
      ref={ref}
      {...props}
    >
      {backgroundVideo?.desktop && (
        <video
          className={oneLine`
            absolute inset-0 w-full h-full object-cover
            ${backgroundVideo?.mobile ? `hidden md:block` : ''}
          `}
          autoPlay
          loop
          muted
          preload="auto"
        >
          {backgroundVideo?.desktop.map((video, index) => (
            <source
              src={video.src}
              {...(video.type && {
                type: video.type,
              })}
              key={`${video.src}-${index}`}
            />
          ))}
        </video>
      )}

      {backgroundVideo?.mobile && (
        <video
          className={oneLine`
            absolute inset-0 w-full h-full object-cover
            ${backgroundVideo?.desktop ? 'block md:hidden' : ''}
          `}
          autoPlay
          loop
          muted
          preload="auto"
        >
          {backgroundVideo?.mobile.map((video, index) => (
            <source
              src={video.src}
              {...(video.type && {
                type: video.type,
              })}
              key={`${video.src}-${index}`}
            />
          ))}
        </video>
      )}

      {!(backgroundTop || backgroundBottom) && (
        <BackgroundImage
          backgroundImage={backgroundImage}
          overlay={overlay}
          notLazy={backgroundLazy}
        />
      )}

      {backgroundTop && (
        <div
          className="absolute top-0 left-0 right-0 h-1/2"
          style={{ backgroundColor: backgroundTop.backgroundColor }}
        >
          <BackgroundImage
            backgroundImage={backgroundTop.backgroundImage}
            overlay={backgroundTop.overlay}
            notLazy={backgroundLazy}
          />
        </div>
      )}

      {wrapper ? (
        <div
          className={oneLine`
          u-wrapper flex flex-col h-full
          ${wrapperSize ?? ''}
          ${justify ?? ''}
          ${align ?? ''}
          ${textAlign ?? ''}
          ${classNameWrapper ?? ''}
        `}
          style={{ zIndex: zIndex }}
        >
          {children}
        </div>
      ) : children &&
        (backgroundBottom ||
          backgroundImage ||
          backgroundTop ||
          backgroundVideo) ? (
        styleInjector({ zIndex: zIndex }, children)
      ) : (
        children
      )}

      {backgroundBottom && (
        <div
          className="absolute bottom-0 left-0 right-0 h-1/2"
          style={{ backgroundColor: backgroundBottom.backgroundColor }}
        >
          <BackgroundImage
            backgroundImage={backgroundBottom.backgroundImage}
            overlay={backgroundBottom.overlay}
            notLazy={backgroundLazy}
          />
        </div>
      )}
    </HTMLTag>
  );
};

export const Container = forwardRef(BaseContainer);
