import { TypeAs, TypeChildren, TypeClassName } from '@/types';

export const enum ButtonVariant {
  PRIMARY = 'PRIMARY',
  DISPLAY = 'DISPLAY',
  ICON = 'ICON',
  TEXTINPUT = 'TEXTINPUT',
}

export interface ButtonProps<T extends React.ElementType>
  extends TypeClassName,
    TypeChildren,
    TypeAs<T> {
  /**
   * Indica el tipo de boton a mostrar
   */
  variant: ButtonVariant;
  /**
   * Si el botón es outline o no
   */
  outline?: boolean;
  /**
   * ¿Está cargando?
   */
  loading?: boolean;
}
