import { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { clearCart } from '@/lib/carts';
import { fetchOrders } from '@/lib/orders';
import { loadCart } from '@/lib/carts';

const BaseLayout = dynamic(() => import('./BaseLayout'), {
  ssr: true,
});

const PrivateAreaLayout = dynamic(() => import('./PrivateAreaLayout'), {
  ssr: true,
});
import { TypeLayouts } from './types';
import { Meta, MetaProp } from "./meta";

/**
 * Layout
 */
const Layout = ({
  layoutType,
  children,
  meta,
  canonical,
  ...props
}: {
  theme: string;
  preFooter?: boolean;
  children: ReactNode | ReactNode[];
  layoutType: TypeLayouts;
  title?: string;
  className?: string;
  meta?: MetaProp[],
  canonical: string,
  blocks
}): JSX.Element => {
  const dispatch = useDispatch();
  const { query } = useRouter();

  if (query.checkout === 'ok') {
    dispatch(clearCart());
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loadCart());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchOrders());

  }, [dispatch]);

  const SelectedLayout = useMemo(() => {
    switch (props.title == '404' ? '404' : layoutType) {
      case 'account':
        return PrivateAreaLayout;
      default:
        return BaseLayout;
    }
  }, [layoutType, props.title]);
  return <SelectedLayout {...props}><Meta meta={meta} canonical={canonical} />{children}</SelectedLayout>;
};
export default Layout;
