export const defaultProps = {
  color: 'currentColor',
};

export type IconType = {
  /**
   * Permite añadir clases al icono
   */
  className?: string;
  /**
   * Define la anchura del icono
   */
  width?: number | string;
  /**
   * Define la altura del icono
   */
  height?: number | string;
  /**
   * Define el color del icono
   */
  color?: string;
};
