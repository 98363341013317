import { ComponentPropsWithRef, ElementType, Ref, forwardRef } from 'react';
import { oneLine } from 'common-tags';

import '@/lib/utils/forwardRef';

import { CaretLeft, CaretRight } from '@/icons';

import { LinkProps, LinkVariant } from './types';

const VARIANT_MAPS: Record<LinkVariant, string> = {
  [LinkVariant.POSITIVE]: 'text-primary-500 hover:text-primary-600',
  [LinkVariant.NEGATIVE]: 'text-primary-50 hover:text-primary-50',
  [LinkVariant.ERROR]: 'text-tertiary-500 hover:text-tertiary-600',
  [LinkVariant.CURRENTCOLOR]: '',
};

const LinkBase = <T extends ElementType = 'a'>(
  {
    className = '',
    variant = LinkVariant.POSITIVE,
    children,
    as,
    disabled = false,
    icon,
    largerFont,
    ...props
  }: LinkProps<T> & Omit<ComponentPropsWithRef<T>, keyof LinkProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'a';
  return (
    <HTMLTag
      className={oneLine`
        inline-flex gap-x-2 items-center text-caption ${largerFont ? 'text-sm' : 'text-caption-s'} md:text-caption--m
        ${VARIANT_MAPS[variant]}
        ${disabled ? 'opacity-20' : ''}
        ${className}
      `}
      ref={ref}
      {...props}
    >
      {icon === 'left' ? <CaretLeft width={24} /> : null}
      {children}
      {icon === 'right' ? <CaretRight width={24} /> : null}
    </HTMLTag>
  );
};

export const Link = forwardRef(LinkBase);
